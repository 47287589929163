import React from "react";
import "./lokasi.scss";
import lokasiimages from "../../Media/Screenshot 2024-11-22 172452.png";

const lokasi = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=62817240217&text=Halo%20Handryan,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20The%20Gramercy%20(Lokasi)%20https://gramercy-alamsutera.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  return (
    <div id="lokasi" className="container-lokasi">
      <div className="container-judul">
        <div className="judul-lokasi">
          <h1>Lokasi </h1>
          <h2>The Gramercy</h2>
          <h3>Alam Sutera</h3>
          <div className="deskripsi">
            <h4>Lokasi yang ideal</h4>
            <h5>untuk tinggal dan berbisnis</h5>
            <h6>dengan banyak infrastruktur</h6>
          </div>
        </div>

        <div className="button-penawaran">
          <button className="buttonpenawaran" onClick={handleConsultationClick}>
            Learn More
          </button>
        </div>
      </div>
      <div className="lokasi">
        <img
          className="lokasi-tallasa"
          src={lokasiimages}
          alt="hardsell-summarecon-serpong"
        />
      </div>
    </div>
  );
};

export default lokasi;

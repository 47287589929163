import React from "react";
import "./Surrounding.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Surroundingimages from "./Surrounding.js";

const settings1 = {
  dots: true,
  arrows: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const settings2 = {
  dots: true,
  arrows: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const About = () => {
  const handleConsultationClick = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=62817240217&text=Halo%20Handryan,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20The%20Gramercy%20(Surrounding%20Area)%20https://gramercy-alamsutera.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };

  return (
    <div className="surrounding">
      <div className="slider-regia">
        <Slider {...settings1}>
          {Surroundingimages.map((image, index) => (
            <img className="imagecard" key={index} src={image} />
          ))}
        </Slider>
      </div>
      <div id="surrounding" className="container-fasilitas">
        <h1>The Gramercy Surounding Area</h1>
        <hr color="#4d4d4d" />
        <div className="container-desk">
          <div className="desksurrounding">
            Selain menawarkan fasilitas yang menarik, The Gramercy Alam Sutera
            juga dikelilingi dengan berbagai macam fasilitas umum, seperti
            lingkungan pendidikan, kesehatan, tempat hiburan serta akses
            transportasi guna mempermudah penghuni mempermudah memenuhi
            kebutuhannya
          </div>
        </div>
        <div className="container-benefit">
          <div className="benefit-list">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "#4d4d4d"}}>&nbsp;&nbsp;IKEA</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "#4d4d4d"}}>&nbsp;&nbsp;AEON Mall</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "#4d4d4d"}}>
                &nbsp;&nbsp;Binus University
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "#4d4d4d"}}>
                &nbsp;&nbsp;Alam Sutera Mall
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "#4d4d4d"}}>
                &nbsp;&nbsp;Brawijaya Hospital
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "#4d4d4d"}}>
                &nbsp;&nbsp;Soekarno-Hatta Airport
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "#4d4d4d"}}>
                &nbsp;&nbsp;Toll Gate Pinang
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "#4d4d4d"}}>
                &nbsp;&nbsp;Kunciran MRT Station
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "#4d4d4d"}}>
                &nbsp;&nbsp;Santa Laurensia School
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckDouble} color="rgb(200, 169, 92)" />
              <span style={{color: "#4d4d4d"}}>
                &nbsp;&nbsp;Living World Mall Alam Sutera
              </span>
            </div>
          </div>
        </div>
        <div className="button">
          <div className="container-wa">
            <button
              className="buttonpenawaran"
              onClick={handleConsultationClick}>
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

import Alca1 from "../Alca/Alca (1).webp"
import Alca2 from "../Alca/Alca (2).webp"
import Alca3 from "../Alca/Alca (3).webp"
import Alca4 from "../Alca/Alca (4).webp"
import Alca5 from "../Alca/Alca (5).webp"
import Alca6 from "../Alca/Alca (6).webp"


const Alcaimages =[
    ,Alca1,Alca2,Alca3,Alca4,Alca5,Alca6
    ]
    
    export default Alcaimages;
    
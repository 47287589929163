import React from "react";
import "./about.scss";
import logo from "../../Media/interior/About.webp";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";

const handleConsultationClick = () => {
  const whatsappLink =
    "https://api.whatsapp.com/send/?phone=62817240217&text=Halo%20Handryan,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20The%20Gramercy%20(About)%20https://gramercy-alamsutera.com//&type=phone_number&app_absent=0";
  window.open(whatsappLink, "_blank");
};

const about = () => {
  return (
    <div id="about" className="container-about">
      <div className="container-deskripsi">
        <div className="judulsumbog">
          <h1>Your Journey to A Grateful Home</h1>
        </div>
        <div className="deskripsi-about">
          The Gramercy Alam Sutera adalah sebuah proyek perumahan mewah yang
          terletak di kawasan Alam Sutera, Tangerang Selatan. Dikenal sebagai
          cluster eksklusif, The Gramercy menawarkan hunian dengan desain
          European Modern yang elegan dan nyaman, dibangun di atas lahan seluas
          sekitar 7,5 hektar dengan total 112 unit rumah.Desain dari The
          Gramercy mengedepankan konsep modern yang mengutamakan ruang terbuka
          hijau, memberikan suasana yang asri dan nyaman.
        </div>
      </div>
      <div className="rowcontainer">
        <div className="featurelist">
          <div className="feature">
            <FontAwesomeIcon icon={faCheckCircle} />
            <span style={{color: "black"}}>
              &nbsp;&nbsp; Strategic Location
            </span>
          </div>
          <div className="feature">
            <FontAwesomeIcon icon={faCheckCircle} />
            <span style={{color: "black"}}>
              &nbsp;&nbsp;Engage with Daily Life Activity
            </span>
          </div>
          <div className="feature">
            <FontAwesomeIcon icon={faCheckCircle} />
            <span style={{color: "black"}}>
              &nbsp;&nbsp;Connecting With Nature
            </span>
          </div>

          <div className="feature">
            <FontAwesomeIcon icon={faCheckCircle} />
            <span style={{color: "black"}}>&nbsp;&nbsp;Elegant Lifestyle</span>
          </div>

          <div className="feature">
            <FontAwesomeIcon icon={faCheckCircle} />
            <span style={{color: "black"}}>&nbsp;&nbsp;Endless Facilities</span>
          </div>
          <div className="feature">
            <FontAwesomeIcon icon={faCheckCircle} />
            <span style={{color: "black"}}>
              &nbsp;&nbsp;Perfect Modern Luxury
            </span>
          </div>
          <div className="feature">
            <FontAwesomeIcon icon={faCheckCircle} />
            <span style={{color: "black"}}>
              &nbsp;&nbsp;Luxurious Residence
            </span>
          </div>
          <div className="button">
            <div className="container-wa">
              <button
                className="buttonpenawaran"
                onClick={handleConsultationClick}>
                Learn More
              </button>
            </div>
          </div>
        </div>
        <div className="logo-about">
          <img
            className="gambar-logo"
            src={logo}
            alt="logo-summarecon-serpong"
          />
        </div>
      </div>
    </div>
  );
};

export default about;

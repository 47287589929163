import Arma1 from "../Arma/Arma (1).webp"
import Arma2 from "../Arma/Arma (2).webp"
import Arma3 from "../Arma/Arma (3).webp"
import Arma4 from "../Arma/Arma (4).webp"
import Arma5 from "../Arma/Arma (5).webp"
import Arma6 from "../Arma/Arma (6).webp"
import Arma7 from "../Arma/Arma (7).webp"
import Arma8 from "../Arma/Arma (8).webp"
import Arma9 from "../Arma/Arma (9).webp"
import Arma10 from "../Arma/Arma (10).webp"
import Arma11 from "../Arma/Arma (11).webp"


const Armaimages =[
    ,Arma1,Arma2,Arma3,Arma4,Arma5,Arma6,Arma7,Arma8,Arma9,Arma10,Arma11
    ]
    
    export default Armaimages;
    
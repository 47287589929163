import React from "react";
import "./home.scss";
import Navbar from "../Section/Header/navbar.jsx";
import Banner from "../Section/Banner/banner";
import About from "../Section/about/about.jsx";
import Promo from "../Section/Promo/promo.jsx";
import Produk from "../Section/Produk/Produk.jsx";
import Fasilitas from "../Section/Fasilitas/fasilitas.jsx";
import Surrounding from "../Section/Surrounding/Surrounding.jsx";
import Lokasi from "../Section/Lokasi/lokasi.jsx";
import Footerup from "../Section/Footerup/footerup.jsx";
import Scrolltohashelement from "../Pages/ScrollToHashElement";
import tombol from "../Media/tombol.webp";
const home = () => {
  const fungsiganteng = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=62817240217&text=Halo%20Handryan,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20The%20Gramercy%20https://gramercy-alamsutera.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  return (
    <div className="home">
      <Scrolltohashelement />
      <Navbar />
      <Banner />
      <About />
      <Promo />
      <Produk />
      <Fasilitas />
      <Surrounding />
      <Lokasi />
      <Footerup />
      <img
        onClick={fungsiganteng}
        src={tombol}
        className="tombolwa"
        alt="Hubungi Sekarang!"
      />
    </div>
  );
};

export default home;

import React from "react";
import "./promo.scss";
import penawaran from "../../Media/Hardsell_Gramercy.webp";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons";

const Penawaran = () => {
  const Cherrywa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=62817240217&text=Halo%20Handryan,%20Saya%20bisa%20minta%20detail%20Katalog,%20Simulasi,%20dan%20Promo%20terbaru%20Produk%20The%20Gramercy%20(Promo)%20https://gramercy-alamsutera.com//&type=phone_number&app_absent=0";
    window.open(whatsappLink, "_blank");
  };
  return (
    <div id="promo" className="promosumbog">
      <div className="promo-sumbog">
        <div className="gambarpromo">
          <img src={penawaran} alt={penawaran} />
        </div>
        <div className="ket-promo">
          <div className="judul">
            <h1>Promo The Gramercy Alam Sutera</h1>
          </div>
          <div className="promolist">
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Soft DP</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Discount up To 300 Juta
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free AC</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free CCTV</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "white"}}>&nbsp;&nbsp;Free canopy</span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Free Smartdoor Lock
              </span>
            </div>
            <div className="penawaranpoin">
              <FontAwesomeIcon icon={faCheckCircle} />
              <span style={{color: "white"}}>
                &nbsp;&nbsp;Free Smarthome System
              </span>
            </div>
          </div>
          <button className="buttonpenawaran" onClick={Cherrywa}>
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
};

export default Penawaran;

import Aera1 from "../Aera/Aera (1).webp"
import Aera2 from "../Aera/Aera (2).webp"
import Aera3 from "../Aera/Aera (3).webp"
import Aera4 from "../Aera/Aera (4).webp"
import Aera5 from "../Aera/Aera (5).webp"
import Aera6 from "../Aera/Aera (6).webp"
import Aera7 from "../Aera/Aera (7).webp"
import Aera8 from "../Aera/Aera (8).webp"
import Aera9 from "../Aera/Aera (9).webp"

const Aeraimages =[
    ,Aera1,Aera2,Aera3,Aera4,Aera5,Aera6,Aera7,Aera8,Aera9
    ]
    
    export default Aeraimages;
    
import Surrounding0 from "../../Media/Surrounding_alsut/AEON Mall.webp"
import Surrounding1 from "../../Media/Surrounding_alsut/Santa Laurensia School.webp"
import Surrounding2 from "../../Media/Surrounding_alsut/Alam Sutera Mall.webp"
import Surrounding3 from "../../Media/Surrounding_alsut/Binus University.webp"
import Surrounding4 from "../../Media/Surrounding_alsut/IKEA.webp"
import Surrounding5 from "../../Media/Surrounding_alsut/Brawijaya Hospital.webp"
import Surrounding6 from "../../Media/Surrounding_alsut/Soekarno-Hatta Airport.webp"
import Surrounding7 from "../../Media/Surrounding_alsut/Toll Gate Pinang.webp"
import Surrounding8 from "../../Media/Surrounding_alsut/Living World Mall.webp"


const Surroundingimages =[
    Surrounding0,Surrounding1,Surrounding2,Surrounding3,Surrounding4,Surrounding5,Surrounding6,Surrounding7,Surrounding8
]

export default Surroundingimages;
import Fasilitas0 from "../../Media/Fasilitas/Fasilitas (0).png"
import Fasilitas1 from "../../Media/Fasilitas/Fasilitas (1).webp"
import Fasilitas2 from "../../Media/Fasilitas/Fasilitas (2).webp"
import Fasilitas3 from "../../Media/Fasilitas/Fasilitas (3).webp"


const Fasilitasimages =[
    Fasilitas0,Fasilitas1,Fasilitas2,Fasilitas3
]

export default Fasilitasimages;
import React from "react";
import logomercy from "../../Media/logo-black.png";
import logoalsut from "../../Media/logo-alsut.png";
import "./footerup.scss";
import {Link} from "react-router-dom";

const footerup = () => {
  return (
    <div className="footerup">
      <div className="containerfooter">
        <div className="desk1">
          <h1>Visit Us!</h1>
          <div className="alamat">
            Jl. Alam Sutera Boulevard Bundaran C, Pd. Jagung Tim., Serpong
            Utara, Kota Tangerang Selatan, Banten - 15326
          </div>
        </div>
        <hr />
        <div className="desk2">
          Dapatkan Informasi Promo, Brosur dan Pricelist Terbaru
        </div>
        <hr />
        <div className="desk3">Contact Us: +6217-240-217 (Handryan)</div>
      </div>
      <div className="containerlogo">
        <div className="logomercy">
          <img src={logomercy} alt={logomercy} />
        </div>
        <div className="logoalsut">
          <img src={logoalsut} alt={logoalsut} />
        </div>
      </div>

      <a href="/privacy-policy">Privacy Policy </a>
      <div className="copyright">
        © All Rights Reserved, The Gramercy Alam Sutera
      </div>
      <div className="powered">Powered by Linktown</div>
    </div>
  );
};

export default footerup;

import React from "react";
import "./Produk.scss";
import data from "./data.json";
import Card from "../../Component/Card/card";
import Alcaimages from "../../Media/interior/Alca/Alca";
import Armaimages from "../../Media/interior/Arma/Arma";
import Aeraimages from "../../Media/interior/Aera/Aera";

const imageMap = {
  Alcaimages,
  Armaimages,
  Aeraimages,
  // Add other image arrays here if you have more sets
};

const produk = () => {
  return (
    <div id="gramercy" className="produk">
      <h1>Welcome to The Gramercy</h1>
      <h2>Where Elegance Meets Comfort</h2>
      <div className="cardhome-containerf">
        {data.cards.map((card, index) => {
          // Determine the logo dynamically
          // Determine the logo dynamically
          return (
            <Card
              key={index}
              carousel={imageMap[card.imagesKey]}
              title={card.title}
              subtitle={card.subtitle}
              price={card.price}
              subprice={card.subprice}
              details={card.details}
              whatsappLink={card.whatsappLink} // Pass the WhatsApp link
            />
          );
        })}
      </div>
    </div>
  );
};

export default produk;
